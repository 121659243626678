import React from "react";

export const image = [
  {
    img: "https://images8.alphacoders.com/545/545909.jpg",
    subtitle: "1"
  },

  {
    img: "https://images6.alphacoders.com/629/629544.jpg",
    subtitle: "2"
  },

  {
    img: "https://images6.alphacoders.com/596/596848.jpg",
    subtitle: "3"
  },

  {
    img: "https://images5.alphacoders.com/587/587597.jpg",
    subtitle: "4"
  }
];
